body {
  margin: 0;
  padding: 10px 20px 10px 20px;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  background-color: #222;
  height: 100vh;
  color: #fff;
}

p {
  font-weight: 100;
}
