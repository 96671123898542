.App {
  text-align: center;
}

.me {
  height: 30vh;
  border-radius: 50%;
  border: 3px solid white;
}

.preferredStack {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  max-width: 220px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.preferredStack a img {
  width: 60px;
}

.links {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  max-width: 120px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: auto;
}

.links a img {
  width: 30px;
}
body {
  margin: 0;
  padding: 10px 20px 10px 20px;
  font-family: "Source Sans Pro", Helvetica, sans-serif;
  background-color: #222;
  height: 100vh;
  color: #fff;
}

p {
  font-weight: 100;
}

