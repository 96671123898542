.App {
  text-align: center;
}

.me {
  height: 30vh;
  border-radius: 50%;
  border: 3px solid white;
}

.preferredStack {
  display: flex;
  flex-direction: row;
  max-width: 220px;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.preferredStack a img {
  width: 60px;
}

.links {
  display: flex;
  flex-direction: row;
  max-width: 120px;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.links a img {
  width: 30px;
}